import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Translation,
  TranslocoLoader,
  TranslocoModule,
  provideTransloco
} from '@jsverse/transloco';
import { TranslocoLocaleModule, provideTranslocoLocale } from '@jsverse/transloco-locale';
import { Inject, Injectable, Injector, NgModule, Optional, PLATFORM_ID, TransferState, isDevMode, makeStateKey } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { Request } from 'express';
import { EnvironmentService } from './service/environment.service';
import { IAppPropertiesPackage, getApps } from '../../config/app-properties';

let url = '';
let app: IAppPropertiesPackage | undefined = {} as IAppPropertiesPackage;

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  app: IAppPropertiesPackage | undefined = {} as IAppPropertiesPackage;

  wcmUrl = makeStateKey<string>('smocaUrl');

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private state: TransferState,
    private environmentService: EnvironmentService,
    @Optional() @Inject(REQUEST) private request: Request,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    if (isPlatformServer(platformId)) {
      url = this.request.url;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.state.set(this.wcmUrl, this.injector.get<string>('WCM_URL' as any));
    } else {
      url = document.location.pathname;
    }
    app = getAppProperties();
  }

  async getTranslation(lang: string): Promise<Translation> {
    const wcmUrl = this.state.get<string>(this.wcmUrl, '');
    const isEnvLocal = this.environmentService.isLocal();

    if (!app && url.includes('/-/')) {
      const formName = await firstValueFrom(this.http.get<Record<string, Record<string, boolean>>>('/form-renderer/--master-list.json').pipe(
        map(_ => {
          const keyExist = Object.keys(_).filter(k => url.includes(k));
          return keyExist.length === 1 ? keyExist[0] : '';
        }),
        catchError(() => of(''))
      ));

      if (!formName) {
        return Promise.resolve({});
      }

      // TODO: change to this once we have translation files hosted online
      // const translationLocation = isEnvLocal ? `/assets/simpleform/i18n/${formName}/${lang}.json` : `${wcmUrl}/content/caas/mplx/${lang}/${formName}.json`;
      return firstValueFrom(this.http.get<Translation>(`/assets/simpleform/i18n/${formName}/${lang}.json`));
    } else if (app) {
      const translationLocation = isEnvLocal || !app.useWCM ? `/assets/${app.appCode}/i18n/${lang}.json` : `${wcmUrl}/content/caas/mplx/${lang}/${app.appCode}.json`;
      return firstValueFrom(this.http.get<Translation>(translationLocation));
    } else {
      return Promise.resolve({});
    }
  }
}

function getAppProperties(): IAppPropertiesPackage | undefined {
  return getApps().find((p) => {
    return p.i18nRoutes.find((r) => {
      return url.includes(`${Object.keys(r)[0]}/${r[Object.keys(r)[0]]}`);
    });
  });
}

export function setAppPackage(newApp: IAppPropertiesPackage): void {
  app = newApp;
}

export function getAppPackage(): IAppPropertiesPackage | undefined {
  return app;
}

export function getSupportedLanguages(): string[] {
  if (app) {
    const supportedLangs: string[] = [];
    app.i18nRoutes?.forEach(r => {
      supportedLangs.push(Object.keys(r)[0]);
    });
    return supportedLangs;
  } else {
    return url.includes('/-/') ? [ 'en', 'fr' ] : [ 'en' ];
  }
}

@NgModule({
  exports: [ TranslocoModule, TranslocoLocaleModule ],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [ 'en' ],
        fallbackLang: 'en',
        defaultLang: 'en',
        reRenderOnLangChange: false,
        prodMode: !isDevMode()
      },
      loader: TranslocoHttpLoader
    }),
    provideTranslocoLocale()
  ]
})
export class TranslocoRootModule { }
