const AppProperties: IAppProperties = {
  package: [
    {
      appCode: 'cli',
      formName: 'cli',
      active: true,
      sso: true,
      i18nRoutes: [
        { 'en': 'credit-limit-increase' },
        { 'fr': 'augmentation-limite-credit' }
      ],
      formSubmitAPI: [ 'FPI' ],
      useWCM: false
    },
    {
      appCode: 'aau',
      formName: 'addAuthUser',
      active: true,
      sso: true,
      i18nRoutes: [
        { 'en': 'add-additional-cardholder' },
        { 'fr': 'ajouter-supplementaire-titulaire' }
      ],
      useWCM: false
    },
    {
      appCode: 'cp',
      formName: 'cp',
      active: true,
      sso: true,
      i18nRoutes: [
        { 'en': 'credit-protection' }
      ],
      useWCM: false
    }
  ]
};

const StaticAppProperties: IAppProperties = {
  package: [ ]
};

/**
 * Application properties with multiple packages
 * @interface IAppProperties
 */
export interface IAppProperties {
  package: (IAppPropertiesPackage | IStaticAppPropertiesPackage)[];
}

/**
 * I18n routing interface
 * @interface I18NRoutes
 */
export interface I18NRoutes {
  [key: string]: string;
}

/**
 * Package object for application properties
 * @interface IAppPropertiesPackage
 */
export interface IAppPropertiesPackage {
  /** MPLX Application Code */
  appCode: string,
  /** Match formName from Valet object to redirect to form */
  formName: string,
  /** If application should be included in compilation */
  active: boolean,
  /** If application requires SSO */
  sso: boolean,
  /** Routes defined by language (ex. { 'en': '<route>', 'fr': '<route>', ... }) */
  i18nRoutes: I18NRoutes[],
  /** Defines where outgoing data goes through submitForm mutation */
  formSubmitAPI?: ('FPI' | 'EBX')[],
  /** If application use content from WCM */
  useWCM: boolean
}

/**
 * Static App object for hosting static application
 * @interface IStaticAppPropertiesPackage
 */
export interface IStaticAppPropertiesPackage {
  /** Application description */
  description?: string
  /** Application MALCode */
  malcode?: string,
  /** If application should be included in compilation */
  active: boolean,
  /** Static application folder location */
  route: string
}

export function getApps(): IAppPropertiesPackage[] {
  return AppProperties.package.filter((p): p is IAppPropertiesPackage => 'appCode' in p);
}

export function getApp(appCode: string): IAppPropertiesPackage | undefined {
  return AppProperties.package.filter((p): p is IAppPropertiesPackage => 'appCode' in p).find(p => p.appCode === appCode);
}

export function getStaticApps(): IStaticAppPropertiesPackage[] {
  return StaticAppProperties.package.filter((p): p is IStaticAppPropertiesPackage => {return 'malcode' in p || 'description' in p;});
}

export function getStaticApp(malcode: string): IStaticAppPropertiesPackage | undefined {
  return StaticAppProperties.package.filter((p): p is IStaticAppPropertiesPackage => 'malcode' in p).find(p => p.malcode === malcode);
}
